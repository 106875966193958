// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import { MLModel, ModelProvider } from '@root/cvat-core-wrapper';
import { ModelData } from './create-model-page/model-form';

export function getProviders(core: any): Promise<ModelProvider[]> {
    return new Promise<ModelProvider[]>((resolve, reject) => {
        core.server.request('/api/functions/info', {
            method: 'GET',
        }).then(async (response: any) => {
            const providersData: Record<string, Record<string, string>> = response.data;
            const providers = Object.entries(providersData).map(([provider, attributes]) => {
                const { icon } = attributes;
                delete attributes.icon;
                return {
                    name: provider,
                    icon,
                    attributes,
                };
            });
            resolve(providers);
        }).catch((error) => {
            reject(error);
        });
    });
}

export async function createModel(core: any, data: ModelData): Promise<any> {
    const result = await core.server.request('/api/functions', {
        method: 'POST',
        data,
    });
    return result.data;
}

export async function deleteModel(core: any, model: MLModel): Promise<void> {
    await core.server.request(`/api/functions/${model.id}`, {
        method: 'DELETE',
    });
}

export async function getFunctions(core: any): Promise<MLModel[]> {
    const result = await core.server.request('/api/functions', {}, { fetchAll: true });
    const { results: functions } = result;
    const models = [];
    for (const model of functions) {
        models.push(
            new MLModel({
                ...model,
            }),
        );
    }

    return models;
}
