// Copyright (C) 2023-2024 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import { useCallback } from '@modules/react';
import { MenuProps } from '@modules/antd/lib/menu';
import { MLModel } from '@root/cvat-core-wrapper';

const OpenModelURLButtonHOC = () => (
    { targetProps }: { targetProps: { model: MLModel } },
): NonNullable<MenuProps['items']>[0] => {
    const { model } = targetProps;

    const onOpenURL = useCallback(() => {
        window.open(model.url, '_blank');
    }, [model]);

    return {
        key: 'open',
        onClick: onOpenURL,
        label: 'Open model URL',
    };
};

export default OpenModelURLButtonHOC;
