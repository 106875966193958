// Copyright (C) 2023-2024 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import { useCallback } from '@modules/react';
import { MenuProps } from '@modules/antd/lib/menu';
import Modal from '@modules/antd/lib/modal';
import notification from '@modules/antd/lib/notification';
import { MLModel } from '@root/cvat-core-wrapper';

const DeleteModelButtonHOC = (onDelete: (model: MLModel) => Promise<void>) => (
    { targetProps }: { targetProps: { model: MLModel } },
): NonNullable<MenuProps['items']>[0] => {
    const { model } = targetProps;
    const deleteModel = useCallback(() => {
        Modal.confirm({
            title: `The model ${model.name} will be deleted`,
            content: 'You will not be able to use it anymore. Continue?',
            className: 'cvat-modal-confirm-remove-model',
            onOk: async () => {
                try {
                    await onDelete(model);
                } catch (exception) {
                    notification.error({
                        message: 'Could not delete model',
                        description: exception.toString(),
                    });
                }
            },
            okButtonProps: {
                type: 'primary',
                danger: true,
            },
            okText: 'Delete',
        });
    }, [model]);

    return {
        key: 'delete',
        onClick: deleteModel,
        label: 'Delete',
    };
};

export default DeleteModelButtonHOC;
