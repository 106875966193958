// Copyright (C) 2023-2024 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React from '@modules/react';
import { Route } from '@modules/react-router';
import {
    MLModel, ModelProviders, User,
} from '@root/cvat-core-wrapper';
import { PluginEntryPoint, ComponentBuilder } from '@root/components/plugins-entrypoint';
import { CombinedState } from '@root/reducers';
import registerFunctionsPlugin from './functions-plugin';
import createFunctionsProxy from './functions-proxy';
import {
    createModel, deleteModel, getProviders,
} from './server';
import store, { Actions } from './store';

import CreateModelPageHOC from './create-model-page/create-model-page';
import CreateModelButtonHOC from './model-action-buttons/create-model-button';
import DeleteModelButtonHOC from './model-action-buttons/delete-model-button';
import OpenModelURLButtonHOC from './model-action-buttons/open-model-url-button';
import ModelProviderIconHOC from './model-provider-icon/model-provider-icon';
import { ModelData } from './create-model-page/model-form';

const PLUGIN_NAME = 'Roboflow & Huggingface functions';

const ModelsComponent: ComponentBuilder = ({
    dispatch, core, REGISTER_ACTION, REMOVE_ACTION, actionCreators,
}) => {
    const functionsProxy = createFunctionsProxy(core);
    registerFunctionsPlugin(core, functionsProxy);

    const { getModelsSuccess } = actionCreators;
    const onModelListUpdate = async (list: MLModel[]): Promise<void> => {
        const { cvatModels } = store.getState();
        const combinedModelList = [...cvatModels, ...list];

        store.dispatch(Actions.updateFunctions(list));
        dispatch(getModelsSuccess(combinedModelList, combinedModelList.length));
    };

    let isProvidersFetching = false;
    const fetchProviders = async (): Promise<void> => {
        try {
            isProvidersFetching = true;
            const providers = await getProviders(core);
            store.dispatch(Actions.updateProviders(providers));
        } finally {
            isProvidersFetching = false;
        }
    };

    const globalStateDidUpdate = (state: CombinedState) => {
        const { providers } = store.getState();
        if (providers === null && state.auth.user !== null && !isProvidersFetching) {
            fetchProviders();
        }
    };

    const onModelCreate = async (data: ModelData): Promise<void> => {
        const response = await createModel(core, data);
        const model = new MLModel({
            ...response,
        });
        const { functions } = store.getState();
        const newList = [...functions, model];
        await onModelListUpdate(newList);
    };

    const onModelDelete = async (model: MLModel): Promise<void> => {
        await deleteModel(core, model);
        const { functions } = store.getState();
        const modelIdx = functions.findIndex((_model: MLModel) => _model.id === model.id);
        if (modelIdx !== -1) {
            functions.splice(modelIdx, 1);
        }
        await onModelListUpdate(functions);
    };

    function ModelsRouter(): JSX.Element {
        return (
            <Route
                key='cvat-plugin-models-router'
                exact
                path='/models/create'
                component={CreateModelPageHOC(store, onModelCreate)}
            />
        );
    }

    const createModelButton = React.memo(CreateModelButtonHOC());

    const deleteModelButton = DeleteModelButtonHOC(onModelDelete);
    const openModelURLButton = OpenModelURLButtonHOC();

    const modelProviderIcon = React.memo(ModelProviderIconHOC(store));

    dispatch({
        type: REGISTER_ACTION,
        payload: {
            path: 'router',
            component: ModelsRouter,
            data: {
                shouldBeRendered: ({ user }: { user: User }) => (user && user.isVerified),
            },
        },
    });

    dispatch({
        type: REGISTER_ACTION,
        payload: {
            path: 'modelsPage.modelItem.topBar.menu.items',
            component: modelProviderIcon,
            data: {
                weight: 10,
                shouldBeRendered: ({ model }: { model: MLModel }) => model.provider !== ModelProviders.CVAT,
            },
        },
    });

    dispatch({
        type: REGISTER_ACTION,
        payload: {
            path: 'modelsPage.topBar.items',
            component: createModelButton,
        },
    });

    dispatch({
        type: REGISTER_ACTION,
        payload: {
            path: 'modelsPage.modelItem.menu.items',
            component: openModelURLButton,
            data: {
                weight: 10,
                shouldBeRendered: ({ model }: { model: MLModel }) => model.provider !== ModelProviders.CVAT,
            },
        },
    });

    dispatch({
        type: REGISTER_ACTION,
        payload: {
            path: 'modelsPage.modelItem.menu.items',
            component: deleteModelButton,
            data: {
                weight: 20,
                shouldBeRendered: ({ model }: { model: MLModel }) => model.provider !== ModelProviders.CVAT,
            },
        },
    });

    return {
        name: PLUGIN_NAME,
        globalStateDidUpdate,
        destructor: () => {
            dispatch({
                type: REMOVE_ACTION,
                payload: {
                    path: 'router',
                    component: ModelsRouter,
                },
            });
            dispatch({
                type: REMOVE_ACTION,
                payload: {
                    path: 'modelsPage.modelItem.topBar.menu.items',
                    component: modelProviderIcon,
                },
            });
            dispatch({
                type: REMOVE_ACTION,
                payload: {
                    path: 'modelsPage.topBar.items',
                    component: createModelButton,
                },
            });
            dispatch({
                type: REMOVE_ACTION,
                payload: {
                    path: 'modelsPage.modelItem.menu.items',
                    component: openModelURLButton,
                },
            });
            dispatch({
                type: REMOVE_ACTION,
                payload: {
                    path: 'modelsPage.modelItem.menu.items',
                    component: deleteModelButton,
                },
            });
        },
    };
};

function register() {
    if (Object.prototype.hasOwnProperty.call(window, 'cvatUI')) {
        (window as any as { cvatUI: { registerComponent: PluginEntryPoint } })
            .cvatUI.registerComponent(ModelsComponent);
    }
}

window.addEventListener('plugins.ready', register, { once: true });
