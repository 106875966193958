// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

export interface ModelProxy {
    run: (body: any) => Promise<any>;
    call: (modelID: string | number, body: any) => Promise<any>;
    status: (requestID: string) => Promise<any>;
    cancel: (requestID: string) => Promise<any>;
}

export default function createFunctionsProxy(core): ModelProxy {
    async function runFunctionRequest(body: any) {
        const response = await core.server.request('/api/functions/requests/', {
            method: 'POST',
            data: body,
        });
        return response.data;
    }

    async function getFunctionRequestStatus(requestID: string) {
        const response = await core.server.request(`/api/functions/requests/${requestID}`, {
            method: 'GET',
        });
        return response.data;
    }

    async function cancelFunctionRequest(requestId: string): Promise<void> {
        await core.server.request(`/api/functions/requests/${requestId}`, {
            method: 'DELETE',
        });
    }

    async function callFunction(funId: string, body: any) {
        const response = await core.server.request(`/api/functions/${funId}/run`, {
            method: 'POST',
            data: body,
        });
        return response.data;
    }

    return {
        status: getFunctionRequestStatus,
        run: runFunctionRequest,
        call: callFunction,
        cancel: cancelFunctionRequest,
    };
}
