// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import {
    MLModel, ModelProviders, RQStatus,
} from '@root/cvat-core-wrapper';
import { getFunctions } from './server';
import store, { Actions } from './store';
import { ModelProxy } from './functions-proxy';

interface FunctionsPlugin {
    name: string;
    description: string;
    cvat: {
        lambda: {
            list: {
                leave: (
                    plugin: FunctionsPlugin,
                    data: { models: MLModel[], count: number }
                ) => Promise<{ models: MLModel[], count: number }>
            },
            requests: {
                leave: (plugin: FunctionsPlugin, requests: any) => any;
            }
        },
        classes: {
            MLModel: {
                prototype: {
                    preview: {
                        leave: () => Promise<string | null>;
                    },
                    delete: {
                        leave: () => Promise<void>;
                    }
                }
            }
        }
    }
    data: {
        listening: Record<number, {
            onUpdate: ((status: RQStatus, progress: number, message?: string) => void)[];
            functionID: string;
            timeout: number | null;
        }>;
    }
}

export default function registerFunctionsPlugin(core: any, functionsProxy: ModelProxy): void {
    core.plugins.register({
        name: 'Functions',
        description: 'Plugin allows to work with external model providers',
        cvat: {
            lambda: {
                list: {
                    async leave(plugun: FunctionsPlugin, data: { models: MLModel[], count: number }) {
                        const functions = await getFunctions(core);

                        const newModelList = [...data.models, ...functions];
                        store.dispatch(Actions.updateFunctions(functions));
                        store.dispatch(Actions.updateCVATModels(data.models));

                        return { models: newModelList, count: data.count + functions.length };
                    },
                },
                requests: {
                    async leave(plugin: FunctionsPlugin, requests: any) {
                        const result = await core.server.request('/api/functions/requests/', {
                            method: 'GET',
                        });
                        const functionsRequests =
                            result.data.filter(
                                (request: any) => [RQStatus.QUEUED, RQStatus.STARTED]
                                    .includes(request.status),
                            );
                        return [...requests, ...functionsRequests];
                    },
                },
                call: {
                    async enter(plugin: FunctionsPlugin, taskID: number, model: MLModel) {
                        const state = store.getState();
                        const externalModel = state.functions.find(
                            (_externalModel: MLModel) => model.id === _externalModel.id,
                        );
                        if (externalModel) {
                            return { preventMethodCallWithReturn: 'external' };
                        }
                        return undefined;
                    },
                    async leave(plugin: FunctionsPlugin,
                        results: 'external' | unknown,  taskID: number, externalModel: MLModel, args: any) {
                        if (results === 'external') {
                            const body = {
                                ...args,
                                task: taskID,
                            };
                            const result = await functionsProxy.call(externalModel.id, body);
                            return result;
                        }
                        return results;
                    },
                },
                run: {
                    async enter(plugin: FunctionsPlugin,
                        taskID: number, model: MLModel) {
                        const state = store.getState();
                        const externalModel = state.functions.find(
                            (_externalModel: MLModel) => model.id === _externalModel.id,
                        );
                        if (externalModel) {
                            return { preventMethodCallWithReturn: 'external' };
                        }
                        return undefined;
                    },
                    async leave(
                        plugin: FunctionsPlugin,
                        results: 'external' | unknown,
                        taskID: number,
                        model: MLModel,
                        args: any,
                    ) {
                        if (results === 'external') {
                            const body = {
                                ...args,
                                task: taskID,
                                function: model.id,
                            };

                            const result = await functionsProxy.run(body);
                            return result.id;
                        }
                        return results;
                    },
                },
                listen: {
                    async enter(
                        plugin: FunctionsPlugin,
                        requestID: string,
                        functionID: string,
                    ) {
                        const state = store.getState();
                        const externalModel = state.functions.find(
                            (_externalModel: MLModel) => functionID === _externalModel.id,
                        );
                        if (externalModel) {
                            return { preventMethodCallWithReturn: 'external' };
                        }
                        return undefined;
                    },
                    async leave(
                        plugin: FunctionsPlugin,
                        results: 'external' | unknown,
                        requestID: string,
                        functionID: string,
                        callback: (status: RQStatus, progress: number, message?: string) => void,
                    ) {
                        if (results === 'external') {
                            const { listening } = plugin.data;
                            if (requestID in listening) {
                                // already listening, avoid sending extra requests
                                listening[requestID].onUpdate.push(callback);
                                return;
                            }
                            const timeoutCallback = (): void => {
                                functionsProxy.status(requestID).then((response) => {
                                    const { status } = response;
                                    if (requestID in listening) {
                                        // check it was not cancelled
                                        const { onUpdate } = listening[requestID];
                                        if ([RQStatus.QUEUED, RQStatus.STARTED].includes(status)) {
                                            onUpdate.forEach((update) => update(status, response.progress || 0));
                                            listening[requestID].timeout = window
                                                .setTimeout(
                                                    timeoutCallback, status === RQStatus.QUEUED ? 30000 : 10000,
                                                );
                                        } else if (status === RQStatus.FINISHED) {
                                            onUpdate
                                                .forEach((update) => update(status, response.progress || 100));
                                        } else {
                                            onUpdate
                                                .forEach((update) => update(status, response.progress || 0, response.exc_info || ''));
                                        }
                                    }
                                }).catch((error) => {
                                    if (requestID in listening) {
                                        // check it was not cancelled
                                        const { onUpdate } = listening[requestID];
                                        onUpdate
                                            .forEach((update) => update(
                                                RQStatus.UNKNOWN,
                                                0,
                                                `Could not get a status of the request ${requestID}. ` +
                                                `${error.toString()}`,
                                            ));
                                    }
                                }).finally(() => {
                                    if (requestID in listening) {
                                        listening[requestID].timeout = null;
                                    }
                                });
                            };
                            listening[requestID] = {
                                onUpdate: [callback],
                                functionID,
                                timeout: window.setTimeout(timeoutCallback),
                            };
                        }
                        return results;
                    },
                },
                cancel: {
                    async enter(plugin: FunctionsPlugin, requestID: string, functionID: string) {
                        const state = store.getState();
                        const externalModel = state.functions.find(
                            (_externalModel: MLModel) => functionID === _externalModel.id,
                        );
                        if (externalModel) {
                            return { preventMethodCallWithReturn: 'external' };
                        }
                        return undefined;
                    },
                    async leave(plugin: FunctionsPlugin, results: 'external' | unknown, requestID: string) {
                        if (results === 'external') {
                            if (plugin.data.listening[requestID]) {
                                clearTimeout(plugin.data.listening[requestID].timeout);
                                delete plugin.data.listening[requestID];
                            }

                            await functionsProxy.cancel(requestID);
                        }
                        return results;
                    },
                },
            },
            classes: {
                MLModel: {
                    prototype: {
                        preview: {
                            async leave() {
                                if (this.provider !== ModelProviders.CVAT) {
                                    const modelID = this.id;
                                    return new Promise((resolve, reject) => {
                                        core.server.request(`/api/functions/${modelID}/preview`, {
                                            method: 'GET',
                                            responseType: 'blob',
                                        }).then((response) => {
                                            const reader = new FileReader();
                                            reader.onload = () => {
                                                resolve(reader.result);
                                            };
                                            reader.readAsDataURL(response.data);
                                        }).catch((err) => {
                                            reject(err);
                                        });
                                    });
                                }
                                return null;
                            },
                        },
                    },
                },
            },
        },
        data: {
            listening: {},
        },
    });
}
