// Copyright (C) 2023-2024 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React from '@modules/react';
import { Provider, useSelector } from '@modules/react-redux';
import { ModelProvider, MLModel } from '@root/cvat-core-wrapper';
import { FunctionsState } from '../store';

export function ModelProviderIcon(
    { provider }: { provider: string | null },
): JSX.Element | null {
    const providers = useSelector((state: FunctionsState) => state.providers);
    const providerInstance = providers.find((_provider: ModelProvider) => _provider.name === provider);

    let icon: JSX.Element | null = null;
    if (providerInstance) {
        icon = (
            <img
                src={`data:image/svg+xml;utf8,${encodeURIComponent(providerInstance.icon)}`}
                alt={providerInstance.name}
            />
        );
    }
    return icon;
}

const ModelProviderIconHOC = (store) => function (
    { targetProps }: { targetProps: { model: MLModel } },
): JSX.Element | null {
    const { model } = targetProps;

    return (
        <Provider store={store}>
            <ModelProviderIcon provider={model.provider} />
        </Provider>
    );
};

export default ModelProviderIconHOC;
