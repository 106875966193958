// Copyright (C) 2023-2024 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';

import React from '@modules/react';
import { useSelector, Provider } from '@modules/react-redux';
import { Row, Col } from '@modules/antd/lib/grid';
import Text from '@modules/antd/lib/typography/Text';
import Spin from '@modules/antd/lib/spin';
import ModelForm, { ModelData } from './model-form';
import { FunctionsState } from '../store';

export function CreateModelPage(
    { onModelCreate } : { onModelCreate: (data: ModelData) => Promise<void> },
): JSX.Element | null {
    const providers = useSelector((state: FunctionsState) => state.providers);

    return (
        <div className='cvat-create-model-page'>
            <Row justify='center' align='middle'>
                <Col>
                    <Text className='cvat-title'>Add a model</Text>
                </Col>
            </Row>
            {
                !providers ? (
                    <div className='cvat-empty-models-list'>
                        <Spin size='large' className='cvat-spinner' />
                    </div>
                ) : (
                    <Row justify='center' align='top'>
                        <Col md={20} lg={16} xl={14} xxl={9}>
                            <ModelForm providers={providers} fetching={false} onModelCreate={onModelCreate} />
                        </Col>
                    </Row>
                )
            }
        </div>
    );
}

const CreateModelPageHOC = (store, onModelCreate: (data: ModelData) => Promise<void>) => function (): JSX.Element {
    return (
        <Provider store={store}>
            <CreateModelPage onModelCreate={onModelCreate} />
        </Provider>
    );
};

export default CreateModelPageHOC;
