// Copyright (C) 2023-2024 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React, { useCallback } from '@modules/react';
import { useHistory } from '@modules/react-router';
import { PlusOutlined } from '@modules/@ant-design/icons';
import Button from '@modules/antd/lib/button';

import './styles.scss';

const CreateModelButtonHOC = () => function (): JSX.Element {
    const history = useHistory();

    const onModelCreate = useCallback(() => {
        history.push('/models/create');
    }, []);

    return (
        <div className='cvat-models-add-wrapper'>
            <Button
                type='primary'
                className='cvat-create-model'
                icon={<PlusOutlined />}
                onClick={onModelCreate}
            />
        </div>
    );
};

export default CreateModelButtonHOC;
